<template>
	<v-container>
		<h1>{{ test.name }}</h1>

		<v-card
			outlined
			:loading="loading"
			class="mt-8">
			<v-item-group>
				<v-sheet
					class="overflow-y-auto">
					<v-item
						v-for="({
							id,
							text,
							images,
							result,
							answers,
							userAnswers,
						}, index) in questions"
						:key="id">
						<template v-slot="{ active, toggle }">
							<v-sheet
								:color="active ? '' : (result ? 'success lighten-3' : 'pink lighten-4')"
								class="px-4 pt-4 pb-3 cursor-pointer"
								:class="active ? '' : (result ? 'success--text' : 'pink--text lighten-4')"
								@click="toggle">
								<div
									class="transition-swing">
									{{ index + 1 }}. {{ text }}
								</div>

								<v-expand-transition>
									<v-responsive
										v-if="active">

										<v-img
											v-for="(image, key) in images"
											:key="key"
											:src="image.original_url"
											contain
											max-width="500"
											max-height="250">
										</v-img>

										<div class="d-flex flex-column align-start">
											<v-chip
												v-for="answer in answers"
												:key="answer.id"
												:color="userAnswers && userAnswers.indexOf(answer.id) >= 0 ? (answer.is_correct ? 'success lighten-3' : 'pink lighten-4') : undefined"
												:text-color="userAnswers && userAnswers.indexOf(answer.id) >= 0 ? (answer.is_correct ? 'success' : 'pink') : undefined"
												class="my-1"
												label>
												<v-icon
													v-if="answer.is_correct"
													color="success"
													left>
													mdi-check-circle-outline
												</v-icon>
												<v-icon
													v-if="userAnswers && userAnswers.indexOf(answer.id) >= 0"
													color="inherit"
													left>
													mdi-gesture-double-tap
												</v-icon>
												{{ answer.text }}
											</v-chip>
										</div>
										<div class="grey--text mt-6">
											<small>Умовні позначення</small>
										</div>
										<div class="grey--text mb-2">
											<div>
												<v-icon small>
													mdi-check-circle-outline
												</v-icon>
												<small> - правильна відповідь на питання</small>
											</div>
											<div>
												<v-icon small>
													mdi-gesture-double-tap
												</v-icon>
												<small> - відповідь, обрана учнем</small>
											</div>
										</div>
									</v-responsive>
								</v-expand-transition>
							</v-sheet>
						</template>
					</v-item>
				</v-sheet>
			</v-item-group>
		</v-card>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import Result from '../models/Result'

export default {
	props: {
		resultId: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		loading: false,
	}),
	computed: {
		result() {
			return Result.find(this.resultId) || {}
		},
		test() {
			return this.result?.test || {}
		},
		questions() {
			return this.result?.data?.questions || []
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadResult() {
			try {
				this.loading = true
				await Result.fetchGet(this.resultId)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити результати тесту',
					color: 'error',
				}, { root: true })
				throw error
			} finally {
				this.loading = false
			}
		},
	},
	mounted() {
		this.loadResult()
	},
}
</script>