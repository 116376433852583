var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.test.name))]),_c('v-card',{staticClass:"mt-8",attrs:{"outlined":"","loading":_vm.loading}},[_c('v-item-group',[_c('v-sheet',{staticClass:"overflow-y-auto"},_vm._l((_vm.questions),function(ref,index){
					var id = ref.id;
					var text = ref.text;
					var images = ref.images;
					var result = ref.result;
					var answers = ref.answers;
					var userAnswers = ref.userAnswers;
return _c('v-item',{key:id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var active = ref.active;
					var toggle = ref.toggle;
return [_c('v-sheet',{staticClass:"px-4 pt-4 pb-3 cursor-pointer",class:active ? '' : (result ? 'success--text' : 'pink--text lighten-4'),attrs:{"color":active ? '' : (result ? 'success lighten-3' : 'pink lighten-4')},on:{"click":toggle}},[_c('div',{staticClass:"transition-swing"},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(text)+" ")]),_c('v-expand-transition',[(active)?_c('v-responsive',[_vm._l((images),function(image,key){return _c('v-img',{key:key,attrs:{"src":image.original_url,"contain":"","max-width":"500","max-height":"250"}})}),_c('div',{staticClass:"d-flex flex-column align-start"},_vm._l((answers),function(answer){return _c('v-chip',{key:answer.id,staticClass:"my-1",attrs:{"color":userAnswers && userAnswers.indexOf(answer.id) >= 0 ? (answer.is_correct ? 'success lighten-3' : 'pink lighten-4') : undefined,"text-color":userAnswers && userAnswers.indexOf(answer.id) >= 0 ? (answer.is_correct ? 'success' : 'pink') : undefined,"label":""}},[(answer.is_correct)?_c('v-icon',{attrs:{"color":"success","left":""}},[_vm._v(" mdi-check-circle-outline ")]):_vm._e(),(userAnswers && userAnswers.indexOf(answer.id) >= 0)?_c('v-icon',{attrs:{"color":"inherit","left":""}},[_vm._v(" mdi-gesture-double-tap ")]):_vm._e(),_vm._v(" "+_vm._s(answer.text)+" ")],1)}),1),_c('div',{staticClass:"grey--text mt-6"},[_c('small',[_vm._v("Умовні позначення")])]),_c('div',{staticClass:"grey--text mb-2"},[_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check-circle-outline ")]),_c('small',[_vm._v(" - правильна відповідь на питання")])],1),_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-gesture-double-tap ")]),_c('small',[_vm._v(" - відповідь, обрана учнем")])],1)])],2):_vm._e()],1)],1)]}}],null,true)})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }